<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Rincian Pendaftaran Uji Konek</h6>
          </b-col>
          <b-col lg="6" cols="7" style="display:flex;justify-content:flex-end">
                <base-button  @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>

      <b-container v-if="isLoading">
        <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
      </b-container>
  
      <div v-else class="container-fluid mt--6">
        <div class="row justify-content-center mb--4">
          <div  class="col">
            <div class="card">
              <div class="card-header">
                <h6 class="surtitle">Rincian</h6>
                <h5 class="h3 mb-0">Uji Konektivitas</h5>
              </div>

              <!-- Industri -->
              <div class="card-body" v-if="isObjectEmpty(listData.other_comp)">
                <ul class="list-group">
                    <!-- <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>ID Pendaftaran</strong>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ listData.id }}</span>
                          </div>
                        </div>
                    </li> -->

                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>Tanggal Uji</strong>
                          </div>
                          <div class="col-lg-9">
                            <!-- {{ listData[0].validates[0].tanggal_uji }} -->
                          </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>Status Pendaftaran</strong>
                          </div>
                          <div class="col-lg-9">
                            <span class=" p-2 text-center rounded">
                              {{ listData.status}}
                            </span>
                          </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dibuat pada</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ formatDate(listData.created_at) }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penanggung Jawab SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp.siup_pj }}</span>
                        </div>
                      </div>
                    </li>

                    <!-- file -->
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Lampiran SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="getBlobsFile(listData.other_comp.siup_file_id)"
                            target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>
                              {{ listData.other_comp.siup_file_id? "File Terupload" : 'Tidak ada' }}</span>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Alamat Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp.comp_address }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Kabupaten / Kota</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp.kabkot_name}}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Provinsi</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp.province_name}}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Telepon Kantor</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.comp.comp_phone }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email Kantor</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.comp.comp_email }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.comp.user_name }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nomor Handphone Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.comp.user_phone }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.comp.user_email }}</span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>

              <!-- Penyedia Alat dan Jasa -->
              <div class="card-body" v-else>
                <ul class="list-group mb-5">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nama Perusahaan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp.comp_name }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Jenis Perusahaan</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp.comp_type_name }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Provinsi</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp.province_name }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Kabupaten / Kota</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp.kabkot_name }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Alamat</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp.comp_address }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-lg-3">
                        <strong>Nomor Telepon</strong>
                      </div>
                      <div class="col-lg-9">
                        <span>{{ listData.comp.comp_phone }}</span>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul class="list-group">
                    <span class="mb-3"><b>Penyedia Alat dan Sparing</b></span>
                    <!-- <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>ID Pendaftaran</strong>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ listData.id }}</span>
                          </div>
                        </div>
                    </li> -->

                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>Tanggal Uji</strong>
                          </div>
                          <div class="col-lg-9">
                            <!-- {{ listData[0].validates[0].tanggal_uji }} -->
                          </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>Status Pendaftaran</strong>
                          </div>
                          <div class="col-lg-9">
                            <span class=" p-2 text-center rounded">
                              {{ listData.status}}
                            </span>
                          </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dibuat pada</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ formatDate(listData.created_at) }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.other_comp.comp_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penanggung Jawab SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.other_comp.siup_pj }}</span>
                        </div>
                      </div>
                    </li>

                    <!-- file -->
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Lampiran SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="getBlobsFile(listData.other_comp.siup_file_id)"
                            target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>
                              {{ listData.other_comp.siup_file_id? "File Terupload" : 'Tidak ada' }}</span>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Alamat Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.other_comp.comp_address }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Kabupaten / Kota</strong>
                        </div>
                        <div class="col-lg-9">
                          <span></span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Provinsi</strong>
                        </div>
                        <div class="col-lg-9">
                          <span></span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Telepon Kantor</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.other_comp.comp_phone }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email Kantor</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.other_comp.comp_email }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.other_comp.user_name }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nomor Handphone Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.other_comp.user_phone }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.other_comp.user_email }}</span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>

            <!-- LOGGER -->
            <div class="card">
              <div class="card-header">
                <h1 class="m-0">Data Logger</h1>
              </div>
              <div class="card-body" v-for="(logger, index) in listData.loggers" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-12">
                          <span class="badge rounded-pill bg-warning text-white">Data Logger #{{ index + 1}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>UID</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ logger.uid }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Brand</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ logger.brand }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Model / Type</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ logger.type }}</span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>




            <!-- Sensor -->
            <div class="card">
              <div class="card-header">
                  <h1 class="m-0">Data Sensor</h1>
              </div>
              <!-- Ph -->
              <div class="card-body" v-for="(item, index) in listData.sensors.ph" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <span class="badge rounded-pill bg-warning text-white">Sensor - pH</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-10">
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brand</strong>
                          </div>
                          <div class="col-10">{{ item.brand }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Probe</strong>
                          </div>
                          <div class="col-10">{{ item.probe }}</div>
                          <!-- <div class="col-10">{{ item.probe }}</div> -->
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Range Pengukuran</strong>
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Minimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.min_measurement }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Maksimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.max_measurement }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Pengukuran</strong>
                          </div>
                          <div class="col-10">{{ item.measurement_method }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Jadwal Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule}}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brochure File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.brochure_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ (item.brochure_file_id ? "File Terupload" : 'Tidak ada' )}}</span>
                              </a>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Support File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.support_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ item.support_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                              </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
              <!-- COD -->
              <div class="card-body" v-for="(item, index) in listData.sensors.cod" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <span class="badge rounded-pill bg-warning text-white">Sensor - COD</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-10">
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brand</strong>
                          </div>
                          <div class="col-10">{{ item.brand }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Probe</strong>
                          </div>
                          <div class="col-10">{{ item.probe }}</div>
                          <!-- <div class="col-10">{{ item.probe }}</div> -->
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Range Pengukuran</strong>
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Minimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.min_measurement }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Maksimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.max_measurement }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Pengukuran</strong>
                          </div>
                          <div class="col-10">{{ item.measurement_method }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Jadwal Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule}}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brochure File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.brochure_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ (item.brochure_file_id ? "File Terupload" : 'Tidak ada' )}}</span>
                              </a>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Support File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.support_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ item.support_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                              </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
              <!-- TSS -->
              <div class="card-body" v-for="(item, index) in listData.sensors.tss" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <span class="badge rounded-pill bg-warning text-white">Sensor - TSS</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-10">
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brand</strong>
                          </div>
                          <div class="col-10">{{ item.brand }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Probe</strong>
                          </div>
                          <div class="col-10">{{ item.probe }}</div>
                          <!-- <div class="col-10">{{ item.probe }}</div> -->
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Range Pengukuran</strong>
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Minimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.min_measurement }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Maksimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.max_measurement }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Pengukuran</strong>
                          </div>
                          <div class="col-10">{{ item.measurement_method }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Jadwal Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule}}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brochure File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.brochure_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ (item.brochure_file_id ? "File Terupload" : 'Tidak ada' )}}</span>
                              </a>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Support File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.support_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ item.support_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                              </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
              <!-- NH3N -->
              <div class="card-body" v-for="(item, index) in listData.sensors.nh3n" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <span class="badge rounded-pill bg-warning text-white">Sensor - NH3N</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-10">
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brand</strong>
                          </div>
                          <div class="col-10">{{ item.brand }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Probe</strong>
                          </div>
                          <div class="col-10">{{ item.probe }}</div>
                          <!-- <div class="col-10">{{ item.probe }}</div> -->
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Range Pengukuran</strong>
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Minimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.min_measurement }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Maksimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.max_measurement }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Pengukuran</strong>
                          </div>
                          <div class="col-10">{{ item.measurement_method }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Jadwal Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule}}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brochure File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.brochure_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ (item.brochure_file_id ? "File Terupload" : 'Tidak ada' )}}</span>
                              </a>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Support File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.support_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ item.support_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                              </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
              <!-- Debit -->
              <div class="card-body" v-for="(item, index) in listData.sensors.debit" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <span class="badge rounded-pill bg-warning text-white">Sensor - Debit</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-10">
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brand</strong>
                          </div>
                          <div class="col-10">{{ item.brand }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Probe</strong>
                          </div>
                          <div class="col-10">{{ item.probe }}</div>
                          <!-- <div class="col-10">{{ item.probe }}</div> -->
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Range Pengukuran</strong>
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Minimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.min_measurement }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Maksimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ item.max_measurement }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Pengukuran</strong>
                          </div>
                          <div class="col-10">{{ item.measurement_method }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Jadwal Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ item.calibration_schedule}}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brochure File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.brochure_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ (item.brochure_file_id ? "File Terupload" : 'Tidak ada' )}}</span>
                              </a>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Support File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="getBlobsFile(item.support_file_id)"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ item.support_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                              </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>























            <div class="row justify-content-end position-fixed right-0" style="top:50%">
              <base-button @click="validasiHandler" class="mx-3 mb-4" type="danger">
                <!-- {{ modalData.isValidated ? "Riwayat Validasi" : "Validasi" }} -->
                Validasi
              </base-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end position-fixed right-0" style="top:50%">
        <base-button @click="validasiHandler" class="mx-3 mb-4" type="danger">
          <!-- {{ modalData.isValidated ? "Riwayat Validasi" : "Validasi" }} -->
          Riwayat
        </base-button>
      </div>


      <ModalValidasi v-if="showModal" :data="[listData, this.$route.params.id]" :show-modal="showModal" @close="onCloseModal" />

      

    </div>
  </template>
  <script>
  import API from "../../../../api/base_url.js"
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import moment from 'moment'
  import Modal from "@/components/Modal.vue";
  import ModalValidasi from "../ModalValidasi.vue";
  import baseURL from "@/api/base_url_backend.js";

  
  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      Modal,
      ModalValidasi
    },
    data() {
      return {
        showModal:false,
        isLoading:true,
        listData: {},
        listDataLogger: {},
        listDataSensor: {}
      };
    },
    mounted() {
      this.getData()
      // this.getDataLogger()
      // this.getDataSensor()
      // this.getRiwayat()
    },
    methods: {
      isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
      },
      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      getStatus(val) {
        if (val == 1 ) {
          return "Disetujui"
        } else if (val == 2) {
          return "Ditolak"
        } else if (val == 3) {
          return "Data Rusak"
        } else {
          return "Menunggu"
        }
      },
      getStyleObject(val) {
        let backgroundColor;
        switch (val) {
          case "Menunggu Validasi":
            backgroundColor = "rgb(245 158 11)";
            break;
          case "Disetujui":
            backgroundColor = "rgb(20 83 45)";
            break;
          case "Data Rusak":
            backgroundColor = "rgb(147 147 147)";
            break;
          case "Ditolak":
            backgroundColor = "#dc3545";
            break;
          default:
            backgroundColor = "white";
        }

        return {
          backgroundColor,
          // Anda dapat menambahkan properti CSS lainnya di sini
        };
      },
      validasiHandler() {
        console.log('call');
        this.showModal = true;
      },
      onCloseModal() {
        this.showModal = false;
        this.getData();
        this.getRiwayat()
      },
      formatDate(timestamp) {


      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
      },
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`uji-konek/${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          console.log(content.data,'--->data');
          this.listData = content.data
          this.listRiwayat = content.data.approval_histories
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getRiwayat() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/uji-konektivitas/histories?uji_konektivitas_id=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          if (content.data == null) {
          this.listRiwayat = []
          } else {
            this.listRiwayat = content.data
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getDataLogger() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/uji-konektivitas/loggers?uji_konektivitas_id=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listDataLogger = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getDataSensor() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/uji-konektivitas/sensors?uji_konektivitas_id=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listDataSensor = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      back() {
        this.$router.push("/uji-konektivitas");
      },
    },
  };
  </script>
  <style scoped>


.mdl-contents {
  overflow-y: auto;
  max-height: 90%;
}
.mdl-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}
.mdl-header i {
  font-size: 30px;
  color: #00bcd4;
  color: #FFFFFF;
}
.mdl-header p {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

.announcement-modal-backgrounds {
  background: #ff9999;
}

  </style>
  